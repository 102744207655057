.modal {
  background: rgba(251, 236, 217, 0.7);

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  padding: 24px;
  box-sizing: border-box;

  &__overlay {
    background: linear-gradient(104.13deg, #fd7e25 16.91%, #fbb458 89.25%);
    position: fixed;
    width: 690px;
    height: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 30px;
    padding-right: 0;
    border-radius: 58px;
    overflow: hidden;
  }

  &__line {
    position: absolute;
    z-index: 1;
    bottom: 40px;
    left: 0px;
    width: 573px;
  }

  &__bottom {
    position: absolute;
    z-index: 1;
    bottom: -300px;
    right: -400px;
    width: 573px;
    transform: rotate(-30deg);
  }

  &__container {
    padding: 25px 18px;
    padding-top: 50px;
    background-color: #fbecd9;
    max-width: 385px;
    max-height: 520px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    box-shadow: 0px 11px 11px 0px #00000040;
    z-index: 99;
    margin-top: 30px;
    margin-bottom: 30px;

    display: flex;
    justify-content: center;

    flex-direction: column;
    align-items: center;

    border-radius: 58px;
  }

  &__text {
    display: flex;
    flex-direction: column;

    margin-bottom: 22px;

    & h3 {
      color: #4d4d4d;

      text-align: center;
      font-family: Unbounded;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      text-transform: uppercase;
    }

    color: #4d4d4d;
    font-family: Unbounded;
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    max-width: 460px;
  }

  &__close {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 100;

    font-size: 25px;
    font-weight: 700;

    cursor: pointer;

    color: #5e5e5e;
    transition: all 0.5s;

    &:hover {
      color: #ff7e04;
      transition: all 0.5s;
    }
  }

  .modal-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__label {
      margin-bottom: 20px;
      position: relative;
      width: 100%;
    }

    &__applay_photo {
      display: flex;
      gap: 4px;
      justify-content: center;
      align-items: center;
    }

    &__input {
      color: #000;
      width: 100%;
      box-sizing: border-box;

      text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 500;
      line-height: 125%;

      outline: none;

      border-radius: 20px;
      border: none;

      padding: 10px 30px;

      &::placeholder {
        color: #000;

        text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 500;
        line-height: 125%;
      }
    }

    &__textarea {
      padding: 22px 31px;
      width: 100%;
      border-radius: 36px;
      border: none;
      background: #fff;
      resize: none;

      height: 100px;

      box-sizing: border-box;

      margin-bottom: 41px;

      color: #000;

      text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
      font-family: Montserrat;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 125%;
      outline: none;

      &::placeholder {
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%;
      }
    }

    &__send {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;

      .modal-form__input {
        display: none;
      }

      & div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &-text {
        max-width: 111px;
        color: #000000;
        background: transparent;

        font-family: Unbounded;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 5px;
      }
    }

    &__changed-avatar {
      padding: 8px 20px;
      background-color: #fec89b;
      border-radius: 68px;
      width: 44px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      & img {
        width: 30px;
        height: 30px;
      }
    }

    &__submit {
      text-align: center;
      font-family: Unbounded;
      font-size: 15px;
      font-weight: 500;

      border-radius: 12px;
      background: #fec89b;
      color: #000;
      border: none;
      padding: 10px 20px;
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        background-color: rgb(254, 133, 20) !important;
        transition: all 0.3s;
      }

      &:active {
        background-color: rgb(45, 45, 45) !important;
        transition: all 0.3s;
      }
    }
  }

  &__after-image {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__image {
    display: flex;
    max-width: 100%;
    position: relative;
    right: 0;
    z-index: 99;

    &-interier,
    &-simple {

      position: relative;
      right: 20px;
    }

    &-mob {
      display: none;
    }
  }
}

@media screen and (max-width: 720px) {
  .modal {
    padding-left: 20px;
    padding-right: 20px;

    &__text {
      color: #ffffff;
      font-size: 14;

      & h3 {
        color: #ffffff;
        font-size: 12px;
      }
    }

    &__container {
      background-color: transparent;
      box-shadow: none;
      padding-top: 0;
    }

    &-form__changed-avatar {
      background-color: #fff !important;
    }
  }



  .modal__line {
    width: 150%;
    height: 150%;
    transform: rotate(30deg);
    top: 0px;
    left: -150px;
    right: auto;
    
  }

  .modal__bottom {

    display: none;
  }

  .modal-form__submit {
    background-color: #3c1c22 !important;
    color: #fec89b !important;
  }

  .modal__overlay {
    justify-content: center;
    width: 90%;
    padding: 20px;
    height: 715px;
  }

  .modal__after-image {
    display: none;
  }

  .modal__image-mob {
    display: block;
  }

  .modal__image-containerMob {
    width: 313px;
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal__image-mob {
    display: block;
    max-width: 90%;
    max-width: 90%;
    height: 90%;
    padding: 10px;
  }

  .modal__image-containerMob {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 600px) {
  .modal {
    padding: 15px;

    .modal__overlay {
      padding: 15px 5px;
      width: 100%;
    }

    &__container {
      padding: 24px;
      padding-top: 50px;
      max-height: 100%;

      position: absolute;
    }

    &__text {

      & h3 {

        text-align: center;
        font-family: Unbounded;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        text-transform: uppercase;
      }

      font-family: Unbounded;
      font-size: 11px;
      font-weight: 400;
      line-height: 17px;
    }

    &__close {
      top: 15px;
    }

    .modal-form {
      height: 100%;

      &__input {
        color: #000;

        text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%;

        &::placeholder {

          text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
          font-family: Montserrat;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 125%;
        }
      }

      &__textarea {

        text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%;
        &::placeholder {
          color: #000;

          text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
          font-family: Montserrat;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 125%;
        }
      }

      &__send {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;


        & div {
          display: flex;
          align-items: center;
          gap: 5px;
          width:fit-content;
          
        }
      }
    }
  }

}

@media screen and (max-width: 450px) {
  .modal__line {
    width: 150%;
    height: 150%;
    transform: rotate(70deg);
    top: 0px;
    left: -150px;
    right: auto;
    
  }

  .modal__overlay {
    padding-bottom: 30px !important;
    position: relative;
  }
}

@media screen and (max-width: 450px) {
  .modal__line {
    width: 150%;
    height: 150%;
    transform: rotate(80deg);
    top: 0px;
    left: -150px;
    right: auto;
    
  }

  .modal__overlay {
    padding-bottom: 30px !important;
  }
}



@media screen and (max-width: 440px) {
  .modal {
    padding-left: 20px;
    padding-right: 20px;

    &__text {
      color: #ffffff;
      font-size: 14;

      & h3 {
        color: #ffffff;
        font-size: 12px;
      }
    }

    &__container {
      background-color: transparent;
      box-shadow: none;
      padding-top: 20px;
    }

    &-form__changed-avatar {
      background-color: #fff !important;
    }
  }

  .modal__close {
    position: absolute;
    top: 0;
    right: 50%;
    transform: translateX(50%);
  }

  .modal__line {
    width: 250%;
    height: 150%;
    transform: rotate(72deg);
    top: 100px;
    left: -320px;
    right: auto;
    
  }

  .modal__bottom {

    display: none;
  }

  .modal-form__submit {
    background-color: #3c1c22 !important;
    color: #fec89b !important;
  }

  .modal__overlay {
    justify-content: center;
    padding-top: 20px;

    width: 90%;
    padding: 20px;
    height: 585px;
  }

  .modal__after-image {
    display: none;
  }

  .modal__image-mob {
    display: block;
    max-width: 90%;
    max-width: 90%;
    height: 90%;
    padding: 10px;
  }

  .modal__image-containerMob {
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal .modal-form__textarea {
    margin-bottom: 15px;
    height: 70px;
  }

  .modal__container {
    padding: 0;
    margin: 0;
  }

  .modal__form {
    padding: 0px 10px;
    box-sizing: border-box;
  }
}


.error {
  position: absolute;
  bottom: -15px;
  left: 10px;

  font-size: 12px;
  color: #f90000;
}

@media screen and (max-width: 720px) {
 
  .error {
    position: absolute;
    bottom: -15px;
    left: 10px;
  
    font-size: 12px;
    color: #fff;
  }
}